

.select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Inter', sans-serif;

    width: 100%;
    position: relative;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    box-sizing: border-box;
    z-index: 1;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: var(--select-search-background);
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: var(--select-search-text);
    border-radius: 0px;
    outline: none;
    font-family: var(--select-search-font);
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    letter-spacing: 0.01rem;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 2px solid var(--select-search-border);
    overflow: auto;
    max-height: 360px;
}
