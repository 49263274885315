
input{
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 36px;
    padding-left: 10px;
}

input[type=checkbox]{
    box-sizing: border-box;
    padding: 0;
    height: 16px;
}
.react-datepicker-popper {
    z-index: 99;
}